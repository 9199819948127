import React, { useEffect, useState } from 'react'

import { ExportOutlined, PlusOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Collapse, Empty, Form, Input, List, Select, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { searchTourPackages } from '../../../../../redux/api/tourPackages.api'
import useTourPackages from '../../../../hooks/useTourPackages'
import { fetchTourPackagesAC } from '../../../../../redux/actions/tourPackages/tourPackages.ac'
import { tourTypes } from '../../../../Constants/itemTypes'

import './styles.scss'
import { DEFAULT_THUMBNAIL } from '../../../../Constants/otherConstsnts'
import ReactQuill from 'react-quill'
import useMyProfile from '../../../../hooks/useMyProfile'
import { ADMIN, SUPER_ADMIN } from '../../../../Constants/roles'

const { Search } = Input;

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const tourTypeOPtions = Object.keys(tourTypes).map(key => {
    return {
        label: getOptionName(tourTypes[key]),
        value: key
    };
});

const TourPackages = () => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const tourPackages = useTourPackages().tourPackages
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filterValues, setFilterValues] = useState({})
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        handleFetchTourPackages(1, {})
    }, [])

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            const response = await searchTourPackages(value)
            addSearchResult(response.data)
        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            return
        }

        handleSearch(value)
    }

    const addSearchResult = (destinations) => {
        const results = destinations.map(destination => {
            return {
                value: destination.id,
                label:
                    <div className='result-row' onClick={() => navigate(`/dashboard/tours/destinations/${destination.id}`)}>
                        <div className='result-row-info'>
                            <h3>{destination.packageName}</h3>
                            <p>{destination.tourType}</p>
                        </div>
                    </div>
            }
        })

        setSearchResults(results)
    }

    const handleFetchTourPackages = async (page, values) => {
        setLoading(true)
        values.page = page
        await dispatch(fetchTourPackagesAC(values))
        setLoading(false)
    }

    const onFilterSubmit = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        setFilterValues(values)
        await handleFetchTourPackages(1, values)
    }

    return (
        <div className='destinations'>
            <div className='destinations__header'>
                <div className='left'>
                    <h1>
                        Tour Packages
                    </h1>
                    <AutoComplete
                        dropdownClassName='certain-category-search-dropdown-destinations'
                        dropdownMatchSelectWidth={350}
                        style={{ width: 350 }}
                        options={searchResults}
                    >
                        <Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            enterButton
                            loading={isSearchLoading}
                            onChange={(e) => onChangeSearch(e.target.value)}
                        />
                    </AutoComplete>
                </div>
                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                    <div className='destinations__header__actions'>
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => navigate('/dashboard/tours/tour-packages/add')}>
                            Add Tour Package
                        </Button>
                    </div>
                }
            </div>

            <div className='filters'>
                <Collapse
                    items={[
                        {
                            key: '1',
                            label: 'Filters',
                            children:
                                <Form
                                    layout='inline'
                                    onFinish={onFilterSubmit}
                                >
                                    <Form.Item
                                        name='tourType'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Tour Type"
                                            options={tourTypeOPtions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='durationDays'
                                    >
                                        <Input placeholder='Duration Days' type='number' min={5} max={30} style={{ width: '130px' }} />
                                    </Form.Item>
                                    {/* <Form.Item
                                        name='starRating'
                                    >
                                        <Input placeholder='Star Rating' type='number' max={5} min={1} style={{ width: '120px' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='maxAge'
                                    >
                                        <Input placeholder='Max Age' type='number' max={100} min={1} style={{ width: '120px' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='minAge'
                                    >
                                        <Input placeholder='Min Age' type='number' max={100} min={1} style={{ width: '120px' }} />
                                    </Form.Item> */}
                                    {/* <Form.Item
                                        name='maxPrice'
                                    >
                                        <Input placeholder='Max Price' style={{ width: '120px' }} />
                                    </Form.Item> */}
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Get Results
                                        </Button>
                                    </Form.Item>
                                </Form>
                        },
                    ]}
                />
            </div>

            <div className='destinations__list'>
                {tourPackages && tourPackages.length > 0 ?
                    <List
                        loading={loading}
                        pagination={{
                            position: 'bottom',
                            align: 'end',
                            pageSize: 5,
                            showSizeChanger: false,
                            total: tourPackages[0]?.filteredTourPackagesCount,
                            current: page,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: (page) => { setPage(page); handleFetchTourPackages(page, filterValues) }
                        }}
                        dataSource={tourPackages}
                        renderItem={(item, index) => (
                            <List.Item key={index} className='destination__item'>
                                <div className='left'>
                                    <img src={item?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='attraction' />
                                </div>
                                <div className='right'>
                                    <div className='destination__item__header'>
                                        <h3>{item?.packageName}</h3>
                                        <Button type='primary' onClick={() => navigate(`/dashboard/tours/tour-packages/${item.id}`)} icon={<ExportOutlined />}>
                                            View
                                        </Button>
                                    </div>
                                    <ReactQuill
                                        value={item?.shotDescription}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <p><strong>Tour Type: </strong>{item?.tourType}</p>
                                    <p><strong>Duration (Days): </strong>{item?.durationDays || 'N/A'}</p>
                                    <p><strong>Starting Price: </strong>{item?.startingPrice + ' AUD' || 'N/A'}</p>
                                </div>
                            </List.Item>
                        )}
                    />
                    :
                    <>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}>
                                    <Spin size='large' />
                                </div>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default TourPackages