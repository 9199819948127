import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import { EventType } from '@azure/msal-browser';
import { msalInstance } from './App/azure/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import configureStore from './redux/configStore';

import theme from './theme/theme.json';
import { ConfigProvider } from 'antd';

const { store } = configureStore();

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
})

if (process.env.REACT_APP_NODE_ENV !== 'development') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn = () => { }
  console.info = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <ConfigProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </MsalProvider>
);
