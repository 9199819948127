import React, { useEffect, useState } from 'react'

import './styles.scss'
import useMyProfile from '../../../hooks/useMyProfile';
import { getActiveUsersCount, getNewBookings, getNewNotifications, getNewRequests, getReviewsCount } from '../../../../redux/api/home.api';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { capitalizeFisrtLetterEachWord } from '../../../helpers/nameRender';

const Home = () => {
    const myProfile = useMyProfile().userProfile;
    const [activeUsers, setActiveUsers] = useState();
    const [loadingActiveUsers, setLoadingActiveUsers] = useState(true);
    const [reviewsCount, setReviewsCount] = useState();
    const [loadingReviewsCount, setLoadingReviewsCount] = useState(true);
    const [newRequests, setNewRequests] = useState();
    const [loadingNewRequests, setLoadingNewRequests] = useState(true);
    const [notifications, setNotifications] = useState();
    const [loadingNotifications, setLoadingNotifications] = useState(true);
    const [bookings, setBookings] = useState();
    const [loadingBookings, setLoadingBookings] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        handleFetchActiveUsers();
        handleFetchReviewsCount();
        handleFetchRequests();
        handleFetchNotifications();
        handleFetchBookings();
    }, []);

    const handleFetchActiveUsers = async () => {
        setLoadingActiveUsers(true);
        try {
            const response = await getActiveUsersCount();
            setActiveUsers(response.data[0]?.totalUsers);
        } catch (error) {
            console.log('ERROR FETCHING ACTIVE USERS', error);
        }
        setLoadingActiveUsers(false);
    }

    const handleFetchReviewsCount = async () => {
        setLoadingReviewsCount(true);
        try {
            const response = await getReviewsCount();
            setReviewsCount(response.data[0]?.totalReviews);
        } catch (error) {
            console.log('ERROR FETCHING REVIEWS COUNT', error);
        }
        setLoadingReviewsCount(false);
    }

    const handleFetchRequests = async () => {
        setLoadingNewRequests(true);
        try {
            const response = await getNewRequests();
            setNewRequests(response?.data?.requests);
        } catch (error) {
            console.log('ERROR FETCHING NEW REQUESTS', error);
        }
        setLoadingNewRequests(false);
    }

    const handleFetchNotifications = async () => {
        setLoadingNotifications(true);
        try {
            const response = await getNewNotifications();
            setNotifications(response.data?.notifications);
        } catch (error) {
            console.log('ERROR FETCHING NOTIFICATIONS', error);
        }
        setLoadingNotifications(false);
    }

    const handleFetchBookings = async () => {
        setLoadingBookings(true);
        try {
            const response = await getNewBookings();
            setBookings(response.data);
        } catch (error) {
            console.log('ERROR FETCHING BOOKINGS', error);
        }
        setLoadingBookings(false);
    }

    const reFormatNotiftionBody = (body) => {
        return body.replace(/\n\s*/g, '');
    };


    return (
        <div className='home'>
            <div className='left'>
                <div className='top'>
                    <div className='box'>
                        <h1>
                            Hi, {myProfile?.userName} 👋
                        </h1>
                        <p>
                            Welcome Back!
                        </p>
                    </div>
                    <div className='box'>
                        <h2>
                            Active Users
                        </h2>
                        <h4>
                            {loadingActiveUsers ? 'Loading...' : activeUsers}
                        </h4>
                    </div>
                    <div className='box'>
                        <h2>
                            Reviews Count
                        </h2>
                        <h4>
                            {loadingReviewsCount ? 'Loading...' : reviewsCount}
                        </h4>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='box'>
                        <h2>
                            New Bookings {bookings?.newPackageBookings.length + bookings?.newCustomTours.length + bookings?.newCustomEvents.length + bookings?.newAccommodationBookings?.length}
                        </h2>

                        <div className='bookings'>
                            {
                                loadingBookings && <div className='loading'>
                                    Loading...
                                </div>
                            }
                            {
                                !loadingBookings && bookings?.newPackageBookings.length === 0 && bookings?.newCustomTours.length === 0 && bookings?.newCustomEvents.length === 0 && <div className='no_bookings'>
                                    No new bookings
                                </div>
                            }
                            {
                                !loadingBookings && bookings?.newPackageBookings.length > 0 && bookings?.newPackageBookings.map((booking, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='booking'
                                            onClick={() => navigate(`/dashboard/bookings/tour-packages/${booking?.id}`)}
                                        >
                                            <h5>
                                                Tour Package
                                            </h5>
                                            <h6>
                                                Tour Start Date: {booking?.tourStartDate}
                                            </h6>
                                            <h6>
                                                Payment Status: {booking?.paymentStatus && capitalizeFisrtLetterEachWord(booking?.paymentStatus)}
                                            </h6>
                                        </div>
                                    )
                                })
                            }
                            {
                                !loadingBookings && bookings?.newCustomTours.length > 0 && bookings?.newCustomTours.map((booking, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='booking'
                                            onClick={() => navigate(`/dashboard/bookings/custom-tours/${booking?.id}`)}
                                        >
                                            <h5>
                                                {booking?.packageName}
                                            </h5>
                                            <h6>
                                                Tour Start Date: {booking?.startDate}
                                            </h6>
                                        </div>
                                    )
                                })
                            }
                            {
                                !loadingBookings && bookings?.newCustomEvents.length > 0 && bookings?.newCustomEvents.map((booking, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='booking'
                                            onClick={() => navigate(`/dashboard/bookings/custom-events/${booking?.id}`)}
                                        >
                                            <h5>
                                                {booking?.packageName}
                                            </h5>
                                            <h6>
                                                Event Type: {booking?.eventType && capitalizeFisrtLetterEachWord(booking?.eventType)}
                                            </h6>
                                            <h6>
                                                Tour Start Date: {booking?.startDate}
                                            </h6>
                                        </div>
                                    )
                                })
                            }
                            {
                                !loadingBookings && bookings?.newAccommodationBookings.length > 0 && bookings?.newAccommodationBookings.map((booking, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='booking'
                                            onClick={() => navigate(`/dashboard/bookings/hotels/${booking?.id}`)}
                                        >
                                            <h5>
                                                Accommodation package {booking?.packageName} for hotel {booking?.accommodationName} on  {booking?.checkIn}
                                            </h5>
                                            <h6>
                                                Check-In: {booking?.checkIn} Check-Out: {booking?.checkOut}
                                            </h6>
                                            <h6>
                                                Adlts: {booking?.adults} | Children: {booking?.childrens}
                                            </h6>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='right'>
                <div className='top'>
                    <div className='box'>
                        <h2>
                            New Requests {newRequests?.length}
                        </h2>
                        <div className='requests'>
                            {
                                loadingNewRequests && <div className='loading'>
                                    Loading...
                                </div>
                            }
                            {
                                !loadingNewRequests && newRequests?.length === 0 && <div className='no_requests'>
                                    No new requests
                                </div>
                            }
                            {
                                !loadingNewRequests && newRequests?.length > 0 && newRequests?.map((request, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='request'
                                            onClick={() => navigate(`/dashboard/support-tickets/${request?.id}`)}
                                        >
                                            <h5>
                                                {request?.subject}
                                            </h5>
                                            <ReactQuill
                                                value={request?.message.length > 100 ? request?.message.slice(0, 100) + '...' : request?.message}
                                                readOnly={true}
                                                theme='bubble'
                                            />
                                            <h6>
                                                {request?.email}
                                            </h6>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='box'>
                        <h2>
                            New Notifications {notifications?.length}
                        </h2>
                        <div className='notifications'>
                            {
                                loadingNotifications && <div className='loading'>
                                    Loading...
                                </div>
                            }
                            {
                                !loadingNotifications && notifications?.length === 0 && <div className='no_notifications'>
                                    No new notifications
                                </div>
                            }
                            {
                                !loadingNotifications && notifications?.length > 0 && notifications?.map((notification, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='notification'
                                            onClick={() => navigate(`/dashboard/messages`)}
                                        >
                                            <h5>
                                                {notification?.notificationTitle}
                                            </h5>
                                            <ReactQuill
                                                value={reFormatNotiftionBody(notification?.notificationBody)}
                                                readOnly={true}
                                                theme='bubble'
                                            />
                                            <h6>
                                                {notification?.updatedAt}
                                            </h6>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home