import { Button, Col, Form, Input, notification, Row, Select, Spin, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';

import './styles.scss';
import useMyProfile from '../../../../hooks/useMyProfile';
import { fetchDestinationNames } from '../../../../../redux/api/destinations.api';
import { fetchAttractionsNames } from '../../../../../redux/api/attractions.api';
import { getAllTourPackagsNameAndIds } from '../../../../../redux/api/tourPackages.api';
import { handleDeleteBlob, handleImageUpload, USER_POSTS } from '../../../../azure/blob';
import { editBlog, getBlogById } from '../../../../../redux/api/blogs.api';
import { TagOutlined } from '@ant-design/icons';


const quillModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
    ]
};

const EditBlog = () => {
    const navigate = useNavigate()
    const myProfile = useMyProfile().userProfile;
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [attractionOptions, setAttractionOptions] = useState([]);
    const [tourOptions, setTourOptions] = useState([]);
    const [blog, setBlog] = useState([])
    const blogId = window.location.pathname?.split('/').pop()
    const statusFormRef = useRef()


    useEffect(() => {
        handleGetBlog()
    }, [blogId])

    useEffect(() => {
        if (blog) {
            statusFormRef?.current?.setFieldsValue({
                blogTitle: blog.blogTitle,
                blogDescription: blog.blogDescription,
                blogTags: blog.blogTags?.split(','),
                tagedDestinations: blog.tagedDestinations?.map(item => item.id),
                tagedAttractions: blog.tagedAttractions?.map(item => item.id),
                taggedTourPackages: blog.taggedTourPackages?.map(item => item.id),
                blogContent: blog.blogContent
            })

            if (blog.blogImages) {
                const images = blog.blogImages.split(',')
                const fileList = images.map((image, index) => {
                    return {
                        uid: index,
                        name: image,
                        status: 'done',
                        url: image
                    }
                })
                setFileList(fileList)
            }
        }
    }, [blog, statusFormRef])

    const handleGetBlog = async () => {
        const response = await getBlogById(blogId)
        if (response.data) {
            setBlog(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        handleGetOptions();
    }, [])

    const handleGetOptions = async () => {
        setLoading(true);

        const resDes = await fetchDestinationNames();
        setDestinationOptions(
            resDes.data.map(item => {
                return {
                    label: item.destinationName,
                    value: item.id
                }
            })
        );

        const resAttr = await fetchAttractionsNames();
        setAttractionOptions(
            resAttr.data.map(item => {
                return {
                    label: item.attractionName,
                    value: item.id
                }
            })
        );

        const res = await getAllTourPackagsNameAndIds();
        setTourOptions(
            res.data.map(item => {
                return {
                    label: item.packageName,
                    value: item.id
                }
            })
        );

        setLoading(false);
    }


    const handleSubmit = async (values) => {
        setLoading(true);
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        let imageUrls = [];

        const name = `${myProfile?.userName}_}`;

        const newImages = fileList.filter(file => !file.url);
        const deletedImages = blog.blogImages?.split(',').filter(image => !fileList.find(file => file.url === image));

        if (newImages?.length > 0) {
            const imageUploadPromises = newImages.map(async (file, index) => {
                return handleImageUpload(
                    USER_POSTS,
                    file.originFileObj,
                    file.size,
                    `${name}_${file.name}`
                ).then((response) => {
                    imageUrls.push(response.blockBlobClient.url);
                });
            });
            await Promise.all(imageUploadPromises);
        }

        // delete blobs
        if (deletedImages?.length > 0) {
            const imageDeletePromises = deletedImages.map(async (image, index) => {
                const blobName = getBlobName(image);
                return handleDeleteBlob(USER_POSTS, blobName);
            });
            await Promise.all(imageDeletePromises);
        }

        let updatedImages = [
            ...fileList.filter((file) => file.url).map((file) => file.url),
            ...imageUrls
        ];
        updatedImages.forEach((url, index) => {
            updatedImages[index] = sanitizeBlobUrls(url);
        });

        values.id = blog?.id;
        values.blogTags = values.blogTags.join(',');
        values.tagedDestinations = values.tagedDestinations?.join(',');
        values.tagedAttractions = values.tagedAttractions?.join(',');
        values.taggedTourPackages = values.taggedTourPackages?.join(',');
        values.blogType = 'admin';

        if (updatedImages.length > 0) {
            values.blogImages = updatedImages.join(',');
        }

        const res = await editBlog(values);
        if (res.status === 200) {
            api.success({
                message: 'Blog Posted Successfully',
                description: 'Your blog has been successfully posted',
            });
            setTimeout(() => {
                navigate(`/dashboard/blogs/${blog.id}`);
            }, 1500);
        } else {
            api.error({
                message: 'Failed to Post Blog',
                description: 'Something went wrong! Please try again later',
            });
        }

        setLoading(false);
    }

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    return (
        <div className='main__contaier custom_tour_plan_main evenet_plan'>
            {contextHolder}

            <div className='content_body_main section_main'>
                <h1 className='header_1'>
                    Write a Blog
                </h1>

                <div className='content_body'>
                    <p>
                        You can write a blog about your travel experiences, tips, and recommendations. Share your stories with the world and inspire others to travel.
                    </p>
                </div>

                <div className='support__container'>
                    <Spin spinning={loading} >
                        {!myProfile?.loading ?
                            <Form
                                layout='vertical'
                                onFinish={handleSubmit}
                                ref={statusFormRef}
                            >
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Blog Title'
                                            name='blogTitle'
                                            rules={[{
                                                required: true,
                                                message: 'Please enter blog title'
                                            }]}
                                        >
                                            <Input placeholder='Enter Blog Title'
                                                maxLength={200} showCount />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Short Description'
                                            name='blogDescription'
                                            rules={[{ required: true, message: 'Please add short descripton' }]}
                                        >
                                            <Input.TextArea
                                                placeholder='Enter Short Description'
                                                maxLength={500}
                                                showCount
                                                rows={4}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Tags'
                                            name='blogTags'
                                            rules={[{ required: true, message: 'Please add tags' }]}
                                        >
                                            <Select
                                                mode="tags"
                                                placeholder="Add Tags"
                                                options={[]}
                                                maxCount={5}
                                                dropdownStyle={{ display: 'none' }}
                                                showSearch={false}
                                                suffixIcon={<TagOutlined />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Related Destinations'
                                            name='tagedDestinations'
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Destinations"
                                                options={destinationOptions}
                                                maxCount={5}
                                                showSearch
                                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Related Attractions'
                                            name='tagedAttractions'
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Attractions"
                                                options={attractionOptions}
                                                maxCount={5}
                                                showSearch
                                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Related Tour Packages'
                                            name='taggedTourPackages'
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Tour Packages"
                                                options={tourOptions}
                                                maxCount={5}
                                                showSearch
                                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Blog Content'
                                            name='blogContent'
                                            rules={[{ required: true, message: 'Please add blog content' }]}
                                        >
                                            <ReactQuill
                                                modules={quillModules}
                                                placeholder='Write your blog content here...'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Upload Images (Max 10)'
                                            name='blogImages'
                                        >
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                onChange={handleChange}
                                                multiple={true}
                                                maxCount={10}
                                                beforeUpload={(file) => {
                                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp';
                                                    if (!isJpgOrPng) {
                                                        api.error({
                                                            message: 'Unsupported image format!',
                                                            description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp)',
                                                        });
                                                    }
                                                    const isSizeValid = file.size / 1024 / 1024 < 2;
                                                    if (!isSizeValid) {
                                                        api.error({
                                                            message: 'Image must be smaller than 2MB!',
                                                        });
                                                    }

                                                    const isVaid = isJpgOrPng && isSizeValid;

                                                    return isVaid ? false : Upload.LIST_IGNORE;
                                                }}
                                            >
                                                {fileList.length <= 10 && '+ Upload'}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '10px'
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        type='primary'
                                        htmlType='submit'
                                        className='custom_primary_btn_2'
                                    >
                                        Post Blog
                                    </Button>
                                </div>
                            </Form>
                            :
                            <div>Loading...</div>
                        }
                    </Spin>
                </  div>
            </div>
        </div>
    )
}

export default EditBlog