import { notification, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import { getAccommodationBookingById } from '../../../../../../redux/api/bookings.api';
import AccommodationBookingDetails from './AccommodationBookingDetails';
import AccommodationPayment from './AccommodationPayment';

const HotelBooking = () => {
    const url = window.location.pathname;
    const bookingId = url.split("/").pop();
    const [booking, setBooking] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = notification.useNotification();

    useEffect(() => {
        handleGetBooking();
    }, []);

    const handleGetBooking = async () => {
        setLoading(true);
        const response = await getAccommodationBookingById(bookingId);
        if (response.status === 200) {
            setBooking(response.data);
        }
        setLoading(false);
    }

    const items = [
        {
            key: '1',
            label: 'Booking Details',
            children:
                <AccommodationBookingDetails
                    booking={booking}
                    handleGetBooking={handleGetBooking}
                    messageApi={messageApi}
                    loading={loading}
                />
        },
        {
            key: '2',
            label: 'Payment Details',
            children:
                <AccommodationPayment
                    booking={booking}
                    handleGetBooking={handleGetBooking}
                    messageApi={messageApi}
                    loading={loading}
                />
        }
    ]

    return (
        <div className='booking_body'>
            {contextHolder}
            {loading ? <h1>Loading...</h1> : booking ? (
                <div>
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                    />
                </div>
            ) : <div>No data found</div>}
        </div>
    )
}

export default HotelBooking