import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { ACTIVE, CANCELLED, CANCELLED_TOUR, COMPLETED, CONFIRMED, INSTALMENT_PAID, INSTALMENT_PENDING, NOT_STARTED, ONGOING, PAID, PENDING, PENDING_PAYMENT, PENDING_VERIFICATION, REJECTED } from '../../../../../Constants/finance';
import { capitalizeFisrtLetterEachWord } from '../../../../../helpers/nameRender';

const TourBookingsTable = ({
    tourBookings,
    loading
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        setTableData(tourBookings)
    }, [tourBookings])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/bookings/tour-packages/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/bookings/tour-packages/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'Tour Package',
            dataIndex: 'tourPackageName',
            key: 'tourPackageName',
            width: '20%',
            ...getNameSearchProps('tourPackageName'),
        },
        {
            title: 'Booking ID',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Start Date',
            dataIndex: 'tourStartDate',
            key: 'tourStartDate',
            width: '20%',
            sorter: (a, b) => new Date(a.tourStartDate) - new Date(b.tourStartDate),
            sortDirections: ['descend'],
            showSorterTooltip: { target: 'full-header' },
        },
        {
            title: 'Booking Status',
            dataIndex: 'bookingStatus',
            key: 'bookingStatus',
            width: '10%',
            // filters: [
            //     {
            //         text: 'Confirmed',
            //         value: CONFIRMED,
            //     },
            //     {
            //         text: 'Pending',
            //         value: PENDING,
            //     },
            //     {
            //         text: 'Rejected',
            //         value: REJECTED
            //     }
            // ],
            // onFilter: (value, record) => record.bookingStatus.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === CONFIRMED) {
                    color = 'green';
                } else if (text === PENDING) {
                    color = 'volcano';
                } else if (text === REJECTED) {
                    color = 'red';
                }
                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : ''}
                    </Tag>
                );
            }
        },
        {
            title: "Tour Status",
            dataIndex: "tourStatus",
            key: "tourStatus",
            width: "10%",
            // filters: [
            //     {
            //         text: 'Pending',
            //         value: ACTIVE,
            //     },
            //     {
            //         text: 'Not Started',
            //         value: NOT_STARTED,
            //     },
            //     {
            //         text: 'Ongoing',
            //         value: ONGOING
            //     },
            //     {
            //         text: 'Completed',
            //         value: COMPLETED
            //     },
            //     {
            //         text: 'Cancelled',
            //         value: CANCELLED_TOUR
            //     }
            // ],
            // onFilter: (value, record) => record.tourStatus.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === ACTIVE) {
                    color = 'volcano';
                } else if (text === NOT_STARTED) {
                    color = 'green';
                } else if (text === ONGOING) {
                    color = 'green';
                } else if (text === COMPLETED) {
                    color = 'green';
                } else if (text === CANCELLED_TOUR) {
                    color = 'red';
                } else {
                    color = "red";
                }

                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : 'Pending Approval'}
                    </Tag>
                );
            },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            width: '10%',
            // filters: [
            //     {
            //         text: 'Pending Payment',
            //         value: PENDING_PAYMENT,
            //     },
            //     {
            //         text: 'Pending Verification',
            //         value: PENDING_VERIFICATION,
            //     },
            //     {
            //         text: 'Paid',
            //         value: PAID
            //     },
            //     {
            //         text: 'Instalment Pending',
            //         value: INSTALMENT_PENDING
            //     },
            //     {
            //         text: 'Instalment Paid',
            //         value: INSTALMENT_PAID
            //     },
            //     {
            //         text: 'Cancelled',
            //         value: CANCELLED
            //     }
            // ],
            render: (text) => {
                let color = 'geekblue';
                if (text === PENDING_PAYMENT) {
                    color = 'volcano';
                } else if (text === PENDING_VERIFICATION) {
                    color = 'green';
                } else if (text === PAID) {
                    color = 'green';
                } else if (text === INSTALMENT_PENDING) {
                    color = 'red';
                } else if (text === INSTALMENT_PAID) {
                    color = 'red';
                } else if (text === CANCELLED) {
                    color = 'red';
                }

                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : ''}
                    </Tag>
                );
            }
        },
        {
            title: 'Final Amount',
            dataIndex: 'finalAmount',
            key: 'finalAmount',
            width: '22%',
            render: (text) => `$${text} AUD`
        },
    ];

    return (
        <Table
            className='table'
            loading={loading}
            columns={columns}
            dataSource={tableData}
        />
    )
}

export default TourBookingsTable