import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Divider, Drawer, Form, Image, Input, Row, Select, Spin, Upload, notification } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { partnerTypes } from '../../../../../Constants/itemTypes';
import { handleImageUpload, PARTNERS_BLOB } from '../../../../../azure/blob';
import { createPartnerProfile } from '../../../../../../redux/api/partners.api';
import { fetchAllNonUsers } from '../../../../../../redux/api/users.api';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const partnerOptions = Object.keys(partnerTypes).map(key => {
    return {
        label: getOptionName(partnerTypes[key]),
        value: key
    };
});

const CreatePartnerProfile = ({
    isVisible,
    setIsVisible,
    handleFetchPartners
}) => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [companyNameLogoUrlFile, setCompanyNameLogoUrlFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Adding...');
    const [api, contextHolder] = notification.useNotification();
    const [nonUsers, setNonUsers] = useState([])
    const formRef = useRef();

    useEffect(() => {
        if (isVisible) {
            handleGetNonUsers()
        }
    }, [isVisible]);

    const handleGetNonUsers = async () => {
        setIsLoading(true)
        setLoadingText('Getting users. Please Wait')
        try {
            const res = await fetchAllNonUsers()
            setNonUsers(res?.data)
        } catch (e) {
            console.groupEnd('Error getting nonusers', e)
        }
        setIsLoading(false)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setCompanyNameLogoUrlFile(newFileList);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let companyNameLogoUrl = '';

            if (companyNameLogoUrlFile.length > 0) {
                setLoadingText('Uploading images...');
                const imageUploadPromises = companyNameLogoUrlFile?.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        PARTNERS_BLOB,
                        file?.originFileObj,
                        file?.size,
                        name
                    ).then((response) => {
                        companyNameLogoUrl = response.blockBlobClient.url;
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            delete values.companyNameLogoUrl;

            if (companyNameLogoUrl) {
                companyNameLogoUrl = sanitizeBlobUrls(companyNameLogoUrl);
                values.companyNameLogoUrl = companyNameLogoUrl;
            }

            setLoadingText('Updating DB...');
            const response = await createPartnerProfile(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Partner profile created successfully'
                });
                setTimeout(() => {
                    setIsVisible(false);
                }, 2000);
                await handleFetchPartners('all', 1);

            } else if (response?.response?.status === 400) {
                api.error({
                    message: 'Error',
                    description: 'Selected user is already a partner.'
                });
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while creating the partner profile.'
                });
            }
        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while creating the partner profile. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl?.split('?')
        return splitUrl[0]
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title={`Add New Partner`}
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="partnerType"
                                    label="Partner Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a partner type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a partner type"
                                        options={partnerOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="userId"
                                    label="Report Person"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a report person'
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a report person"
                                        options={nonUsers?.map(user => {
                                            return {
                                                label: `${user.userName} - ${user.role}`,
                                                value: user.id
                                            }
                                        })}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="companyNameDescription"
                                    label="Company Description"
                                >
                                    <Input.TextArea rows={4} placeholder="Add a description for the company" showCount max={400} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameEmail"
                                    label="Company Email"
                                    rules={
                                        [
                                            {
                                                type: 'email',
                                                message: 'Please enter a valid email address'
                                            },
                                            {
                                                required: true,
                                                message: 'Please enter company email'
                                            }
                                        ]
                                    }
                                >
                                    <Input placeholder="Please enter the company email" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameContactNumber"
                                    label="Company Contact Number"
                                    rules={
                                        [
                                            {
                                                pattern: new RegExp(/^[0-9\b]+$/),
                                                message: 'Please enter a valid phone number'
                                            },
                                        ]
                                    }
                                >
                                    <Input placeholder="Please enter company contact number" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameAddress"
                                    label="Company Address"
                                >
                                    <Input placeholder="Please enter company address" max={100} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameCode"
                                    label="Company Post Code"
                                >
                                    <Input placeholder="Please enter company post code" max={5} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameCountry"
                                    label="Company Country"
                                >
                                    <Input placeholder="Please enter company country" max={10} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameState"
                                    label="Company District"
                                >
                                    <Input placeholder="Please enter company state" max={10} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameCity"
                                    label="Company City"
                                >
                                    <Input placeholder="Please enter company city" max={10} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameWebsite"
                                    label="Company Website"
                                >
                                    <Input placeholder="Please enter company website" max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="bankName"
                                    label="Bank Name"
                                >
                                    <Input placeholder="Please enter bank name" max={20} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="bankBranch"
                                    label="Bank Branch"
                                >
                                    <Input placeholder="Please enter bank branch" max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="bankAccountNumber"
                                    label="Bank Account Number"
                                >
                                    <Input placeholder="Please enter bank account number" max={20} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="bankSwiftCode"
                                    label="Bank Swift Code"
                                >
                                    <Input placeholder="Please enter bank swift code" max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Image'
                                    name='companyNameLogoUrl'
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={companyNameLogoUrlFile}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        multiple={true}
                                        maxCount={10}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {companyNameLogoUrlFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Create Parner Profile
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>


            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default CreatePartnerProfile