import { Divider } from 'antd'
import React from 'react'
import { REQUESTED } from '../../../../../../Constants/activeStatusType'
import { PAID, PENDING_PAYMENT, PENDING_VERIFICATION } from '../../../../../../Constants/finance'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'

const AccommodationPayment = ({
    booking
}) => {
    return (
        <div className='booking_details acc-booking-detals'>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Basic Information
            </Divider>

            <div className='p_container'>
                <p><span>Booking ID: </span>{booking?.id}</p>
                <p><span>Check-In: </span>{booking?.checkIn}</p>
                <p><span>Check-Out: </span>{booking?.checkOut}</p>
                <p><span>Booked Date: </span>{new Date(booking?.updatedAt).toDateString()}</p>
                <p><span>Adults: </span>{booking?.adults}</p>
                <p><span>Children: </span>{booking?.childrens}</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Payment Information
            </Divider>
            <div className='p_container'>
                <p><span>Initial Price: </span>{booking?.initailCostPerPerson} AUD per person per night</p>
                <p><span>Finel Price: </span>{booking?.givenPricePerPerson} AUD per person per night</p>
                <p className={booking?.paymentStatus === REQUESTED ? 'yellow' :
                    booking?.paymentStatus === PENDING_PAYMENT ? 'red' :
                        booking?.paymentStatus === PENDING_VERIFICATION ? 'yellow' :
                            booking?.paymentStatus === PAID ? 'green' :
                                'red'}
                ><span>Payment Status: </span>{booking?.paymentStatus ? capitalizeFisrtLetterEachWord(booking?.paymentStatus) : 'Pending'}
                </p>
                <p><span>Total Price: </span>{booking?.total ? <>{booking?.total} AUD</> : "Not yet updated"}</p>
                {booking?.paymentId && <p><span>Payment ID: </span>{booking?.paymentId}</p>}
            </div>

        </div>
    )
}

export default AccommodationPayment