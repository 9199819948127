import { Button, Checkbox, Col, Divider, Drawer, Form, Input, InputNumber, Radio, Row, Select, Space, Spin, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { REQUESTED } from '../../../../../../Constants/activeStatusType';
import { CANCELLED, CONFIRMED, PAID, PENDING_PAYMENT, PENDING_VERIFICATION } from '../../../../../../Constants/finance';
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender';
import { ExportOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { confirmAccommodationBooking, editAccommodationsBooking } from '../../../../../../../redux/api/bookings.api';

const { TextArea } = Input;

const AccommodationBookingDetails = ({
    booking,
    handleGetBooking,
    messageApi,
    loading
}) => {
    const [openEdit, setOpenEdit] = useState(false);
    const editFromRef = useRef(null)
    const [loadingEditBooking, setLoadingEditBooking] = useState(false)
    const [approveBookingOpen, setApproveBookingOpen] = useState(false)
    const approveFromRef = useRef(null)
    const [loadingApproveBooking, setLoadingApproveBooking] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (booking) {
            const mealsInfo = booking.mealsInfo ? JSON.parse(booking.mealsInfo) : {};
            const selectedMeals = [];
            if (mealsInfo.breakfast) selectedMeals.push('breakfast');
            if (mealsInfo.lunch) selectedMeals.push('lunch');
            if (mealsInfo.dinner) selectedMeals.push('dinner');

            editFromRef?.current?.setFieldsValue({
                checkIn: booking.checkIn,
                checkOut: booking.checkOut,
                adults: booking.adults,
                childrens: booking.childrens,
                meals: selectedMeals,
                mealPreference: mealsInfo.preferences || undefined,
                specialRequirements: booking.specialRequirements,
                email: booking.email,
                phone: booking.phone,
                givenPricePerPerson: booking.givenPricePerPerson,
            });
        }
    }, [booking, editFromRef, openEdit]);

    useEffect(() => {
        if (booking) {
            approveFromRef?.current?.setFieldsValue({
                bookingStatus: booking.bookingStatus,
                paymentStatus: booking.paymentStatus,
            });
        }
    }, [booking, approveFromRef, approveBookingOpen]);

    const handleEditTour = async (values) => {
        setLoadingEditBooking(true);

        // remove empty values
        Object.keys(values).forEach(key => {
            if (values[key] === undefined || values[key] === null || values[key] === '') {
                delete values[key];
            }
        });

        const formattedValues = {
            ...values,
            checkIn: values.checkIn,
            checkOut: values.checkOut,
            total: values.total && values.total?.toString(),
            mealsInfo: {
                breakfast: values.meals.includes('breakfast'),
                lunch: values.meals.includes('lunch'),
                dinner: values.meals.includes('dinner'),
                preferences: values.mealPreference,
            },
            bookingId: booking.id,
        };

        delete formattedValues.dates;
        delete formattedValues.breakfast;
        delete formattedValues.lunch;
        delete formattedValues.dinner;
        delete formattedValues.mealPreference;
        delete formattedValues.meals;

        const response = await editAccommodationsBooking(formattedValues);
        if (response.status === 200) {
            messageApi.success({
                message: 'Booking updated successfully',
                description: 'Booking details has been updated successfully'
            });
            handleGetBooking();
            setOpenEdit(false);
        } else {
            messageApi.error({
                message: 'Failed to update booking',
                description: 'There was an error while updating booking details'
            });
        }

        setLoadingEditBooking(false);
    }

    const handleApproveTour = async (values) => {
        setLoadingApproveBooking(true);

        Object.keys(values).forEach(key => {
            if (values[key] === undefined || values[key] === null || values[key] === '') {
                delete values[key];
            }
        });

        const formattedValues = {
            ...values,
            id: booking.id,
        };

        const response = await confirmAccommodationBooking(formattedValues);
        if (response.status === 200) {
            messageApi.success({
                message: 'Booking status updated successfully',
                description: 'Booking status has been updated successfully'
            });
            handleGetBooking();
            setApproveBookingOpen(false);
        } else {
            messageApi.error({
                message: 'Failed to update booking status',
                description: 'There was an error while updating booking status'
            });
        }

        setLoadingApproveBooking(false);
    }

    return (
        <>
            <div className='booking_details'>
                <div
                    className='actions'
                    style={{ display: 'flex', gap: '10px' }}
                >
                    <Button
                        type='primary'
                        onClick={() => setOpenEdit(true)}
                    >
                        Edit Booking
                    </Button>
                    <Button
                        onClick={() => setApproveBookingOpen(true)}
                    >
                        Change Status
                    </Button>
                </div>

                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Booking Information
                </Divider>
                <div className='p_container'>
                    <p><span>Booking ID: </span>{booking?.id}</p>
                    <p className={booking?.bookingStatus === REQUESTED ? 'yellow' :
                        booking?.bookingStatus === PENDING_PAYMENT ? 'red' :
                            booking?.bookingStatus === PENDING_VERIFICATION ? 'yellow' :
                                booking?.bookingStatus === CONFIRMED ? 'green' :
                                    'red'}
                    ><span>Booking Status: </span>{booking?.bookingStatus ? capitalizeFisrtLetterEachWord(booking?.bookingStatus) : 'Pending'}
                    </p>
                    <p><span>Check-In: </span>{booking?.checkIn}</p>
                    <p><span>Check-Out: </span>{booking?.checkOut}</p>
                    <p><span>Booked Date: </span>{new Date(booking?.createdAt).toDateString()}</p>
                    {
                        booking?.specialRequirements &&
                        <p><span>Special Request: </span>{booking?.specialRequest}</p>
                    }
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Meal Information
                </Divider>
                {booking?.mealsInfo && <p><span>Meals: </span>
                    {JSON.parse(booking?.mealsInfo)?.breakfast && <Tag color="blue">Breakfast</Tag>}
                    {JSON.parse(booking?.mealsInfo)?.lunch && <Tag color="blue">Lunch</Tag>}
                    {JSON.parse(booking?.mealsInfo)?.dinner && <Tag color="blue">Dinner</Tag>}
                </p>}
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Accommodation Package Information
                </Divider>
                <div className='p_container'>
                    <p><span>Package Name: </span>{booking?.accommodationPackage?.packageName}</p>
                    <p><span>Accommodation Type: </span>{booking?.accommodationPackage?.accommodationType && capitalizeFisrtLetterEachWord(booking?.accommodationPackage?.accommodationType)}</p>
                    <p><span>Room Type: </span>{booking?.accommodationPackage?.packageRooms && capitalizeFisrtLetterEachWord(booking?.accommodationPackage?.packageRooms)}</p>
                    <p><span>Package Description: </span>{booking?.accommodationPackage?.packageDescription
                        && <ReactQuill
                            value={booking?.accommodationPackage?.packageDescription}
                            readOnly={true}
                            theme='bubble'
                            style={{ margin: '10px 0', padding: '0' }}
                        />
                    }</p>
                    <p><span>Package Price: </span>{booking?.accommodationPackage?.packagePrice} AUD per night per person</p>
                    <p><span>Package Facilities: </span> {
                        booking?.accommodationPackage?.packageFacilities?.split(',')?.map((facility, index) => (
                            <Tag
                                key={index}
                                color="cyan"
                                style={{ margin: '5px 5px 0 0' }}
                            >{facility && capitalizeFisrtLetterEachWord(facility)}</Tag>
                        ))
                    }</p>
                    <p><span>Package Included Meals: </span> {
                        booking?.accommodationPackage?.packageMeals?.split(',')?.map((facility, index) => (
                            <Tag
                                key={index}
                                color="lime"
                                style={{ margin: '5px 5px 0 0' }}
                            >{facility && capitalizeFisrtLetterEachWord(facility)}</Tag>
                        ))
                    }</p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Accommodation Information

                    <Button
                        type='link'
                        size='small'
                        onClick={() => window.open(`/partners/hotels/${booking?.accommodation?.id}`)}
                        style={{ marginLeft: '10px' }}
                    >
                        View Accommodation Details
                        <ExportOutlined />
                    </Button>
                </Divider>
                <div className='p_container'>
                    <p><span>Accommodation Name: </span>{booking?.accommodation?.accommodationName}</p>
                    <p><span>Accommodation Type: </span>{booking?.accommodationPackage?.accommodationType && capitalizeFisrtLetterEachWord(booking?.accommodationPackage?.accommodationType)}</p>
                    <p><span>City: </span>{booking?.accommodation?.accommodationCity}</p>
                </div>
            </div>

            <Drawer
                title="Edit Booking"
                width={720}
                onClose={() => setOpenEdit(false)}
                open={openEdit}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                loading={loading}
                extra={
                    <Space>
                        <Button onClick={() => setOpenEdit(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                editFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingEditBooking} tip='Updating booking details...'>
                    <Form
                        layout="vertical"
                        ref={editFromRef}
                        onFinish={handleEditTour}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Check-in"
                                    name="checkIn"
                                    rules={[{ required: true }]}
                                >
                                    <Input type='date' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Check-out"
                                    name="checkOut"
                                    rules={[{ required: true }]}
                                >
                                    <Input type='date' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Adults"
                                    name="adults"
                                    initialValue={1}
                                    rules={[{ required: true }]}
                                >
                                    <InputNumber min={1} max={10} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Children"
                                    name="childrens"
                                    initialValue={0}
                                    rules={[{ required: true }]}
                                >
                                    <InputNumber min={0} max={10} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Meals"
                                    name="meals"
                                >
                                    <Checkbox.Group>
                                        <Checkbox value="breakfast">Breakfast</Checkbox>
                                        <Checkbox value="lunch">Lunch</Checkbox>
                                        <Checkbox value="dinner">Dinner</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="mealPreference"
                                    label="Meal Preference"
                                >
                                    <Radio.Group>
                                        <Radio value="vegetarian">Vegetarian</Radio>
                                        <Radio value="non-vegetarian">Non Vegetarian</Radio>
                                        <Radio value="vegan">Vegan</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Special Requirements"
                                    name="specialRequirements"
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Divider orientation="left">Accommodation Package Price</Divider>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Initial Price Per Person"
                                    name="givenPricePerPerson"
                                >
                                    <InputNumber min={1} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Given Price Per Person"
                                    name="givenPricePerPerson"
                                >
                                    <InputNumber min={1} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Total Price"
                                    name="total"
                                >
                                    <InputNumber min={1} style={{ width: "100%" }}
                                        placeholder='Enter total price here (AUD) after confirmation with the hotel' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>

            <Drawer
                title="Change Status"
                width={720}
                onClose={() => setApproveBookingOpen(false)}
                open={approveBookingOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={() => setApproveBookingOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                approveFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingApproveBooking} tip='Updating booking status...'>
                    <Form
                        layout="vertical"
                        ref={approveFromRef}
                        onFinish={handleApproveTour}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='bookingStatus'
                                    label='Booking Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select booking status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select booking status"
                                        allowClear
                                    >
                                        <Select.Option value={CONFIRMED}>
                                            Confirmed
                                        </Select.Option>
                                        <Select.Option value={REQUESTED}>
                                            Requested
                                        </Select.Option>
                                        <Select.Option value={CANCELLED}>
                                            Cancelled
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='paymentStatus'
                                    label='Payment Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select payment status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select payment status"
                                        allowClear
                                    >
                                        <Select.Option value={PENDING_PAYMENT}>
                                            Pending Payment
                                        </Select.Option>
                                        <Select.Option value={PENDING_VERIFICATION}>
                                            Pending Verification
                                        </Select.Option>
                                        <Select.Option value={PAID}>
                                            Paid
                                        </Select.Option>
                                        <Select.Option value={CANCELLED}>
                                            Cancelled
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </>
    )
}

export default AccommodationBookingDetails