import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDestinations from '../../../../hooks/useDestinations';
import { searchDestinations } from '../../../../../redux/api/destinations.api';
import { fetchDestinationsAC } from '../../../../../redux/actions/destinations/destinations.ac';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

import { destinationTypes } from '../../../../Constants/itemTypes';

import { AutoComplete, Button, Empty, Form, Input, List, Select, Spin, Tag } from 'antd';
import {
    PlusOutlined, ExportOutlined
} from '@ant-design/icons';

import './styles.scss'
import { DEFAULT_THUMBNAIL } from '../../../../Constants/otherConstsnts';
import { ADMIN, SUPER_ADMIN } from '../../../../Constants/roles';
import useMyProfile from '../../../../hooks/useMyProfile';
import { ACTIVE } from '../../../../Constants/activeStatusType';


const { Search } = Input;

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const destinationOptions = Object.keys(destinationTypes).map(key => {
    return {
        label: getOptionName(destinationTypes[key]),
        value: key
    };
});

const Destinations = () => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const destinations = useDestinations().destinations
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filterValues, setFilterValues] = useState({})
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        handleFetchdestinations(1)
    }, [])

    useEffect(() => {
    }, [destinations])

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            const response = await searchDestinations(value)
            addSearchResult(response.data)
        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            return
        }

        handleSearch(value)
    }

    const addSearchResult = (destinations) => {
        const results = destinations.map(destination => {
            return {
                value: destination.id,
                label:
                    <div className='result-row' onClick={() => navigate(`/dashboard/tours/destinations/${destination.id}`)}>
                        <div className='result-row-info'>
                            <h3>{destination.destinationName}</h3>
                            <p>{destination.destinationCity}</p>
                        </div>
                    </div>
            }
        })

        setSearchResults(results)
    }

    const handleFetchdestinations = async (page, values) => {
        setLoading(true)
        values = values || filterValues
        values.page = page
        await dispatch(fetchDestinationsAC(values))
        setLoading(false)
    }

    const onFilterSubmit = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        setFilterValues(values)
        await handleFetchdestinations(1, values)
    }

    return (
        <div className='destinations'>
            <div className='destinations__header'>
                <div className='left'>
                    <h1>Destinations</h1>
                    <AutoComplete
                        dropdownClassName='certain-category-search-dropdown-destinations'
                        dropdownMatchSelectWidth={350}
                        style={{ width: 350 }}
                        options={searchResults}
                    >
                        <Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            enterButton
                            loading={isSearchLoading}
                            onChange={(e) => onChangeSearch(e.target.value)}
                        />
                    </AutoComplete>
                </div>
                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                    <div className='destinations__header__actions'>
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => navigate('/dashboard/tours/destinations/add')}>
                            Add Destination
                        </Button>
                    </div>
                }
            </div>
            <div className='destinations__filters'>
                <Form
                    layout='inline'
                    onFinish={onFilterSubmit}
                >
                    <Form.Item
                        name='destinationType'
                    >
                        <Select
                            mode='single'
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Destination Type"
                            options={destinationOptions}
                        />
                    </Form.Item>
                    {/* <Form.Item
                        name='destinationLocation'
                    >
                        <Input placeholder='Destination Location' />
                    </Form.Item> */}
                    <Form.Item
                        name='destinationCity'
                    >
                        <Input placeholder='Destination City' />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading}>
                            Get Results
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <div className='destinations__list'>
                {destinations && destinations.length > 0 ?
                    <List
                        loading={loading}
                        pagination={{
                            position: 'bottom',
                            align: 'end',
                            pageSize: 5,
                            showSizeChanger: false,
                            total: destinations[0]?.filteredDestinationCount,
                            current: page,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: (page) => { setPage(page); handleFetchdestinations(page) }
                        }}
                        dataSource={destinations}
                        renderItem={(item, index) => (
                            <List.Item key={index} className='destination__item'>
                                <div className='left'>
                                    <img src={item?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='destination' />
                                </div>
                                <div className='right'>
                                    <div className='destination__item__header'>
                                        <h3>{item?.destinationName}</h3>
                                        <Button type='primary' onClick={() => navigate(`/dashboard/tours/destinations/${item.id}`)} icon={<ExportOutlined />}>
                                            View
                                        </Button>
                                    </div>
                                    <ReactQuill
                                        value={item?.destinationDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <p><strong>City: </strong>{item?.destinationCity}</p>
                                    <p><strong>Type: </strong>{item?.destinationType || 'N/A'}</p>
                                    <p><strong>Active Status: </strong>
                                        <Tag
                                            color={item?.activeStatus === ACTIVE ? 'green' : 'red'}
                                        >{item?.activeStatus} </Tag>
                                    </p>
                                </div>
                            </List.Item>
                        )}
                    />
                    :
                    <>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}>
                                    <Spin size='large' />
                                </div>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Destinations