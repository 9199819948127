import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllAccommodationBookings } from '../../../../../redux/api/bookings.api'
import AccommodationBookingTable from './AccommodationBookingTable'

const HotelBookings = () => {
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [bookings, setBookings] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        handleFetchBookings(page)
    }, [])

    const handleFetchBookings = async (page) => {
        setLoading(true)
        const result = await getAllAccommodationBookings(page)
        if (result.status === 200) {
            setBookings(result.data)
        }
        setLoading(false)
    }

    return (
        <div className='tour_bookings'>
            <h1>Accommodation Bookings</h1>
            <div className='tour_bookings_table'>
                <AccommodationBookingTable
                    bookings={bookings}
                    handleFetchBookings={handleFetchBookings}
                    page={page}
                    setPage={setPage}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default HotelBookings