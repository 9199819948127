import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import useEvents from '../../../../../hooks/useEvents';
import { editEvent, getEventById } from '../../../../../../redux/api/events.api';
import useDestinations from '../../../../../hooks/useDestinations';

import { ACTIVE, INACTIVE, PENDING } from '../../../../../Constants/activeStatusType';
import { EVENTS_CONTAINER, handleDeleteBlob, handleImageUpload } from '../../../../../azure/blob';

import { Button, Col, Form, Image, Input, notification, Row, Select, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { eventsTypes, weatherTypes } from '../../../../../Constants/itemTypes';

import './styles.scss'


const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};


const weatherOptions = Object.keys(weatherTypes).map(key => {
    return {
        label: getOptionName(weatherTypes[key]),
        value: key
    };
});

const eventOptions = Object.keys(eventsTypes).map(key => {
    return {
        label: getOptionName(eventsTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const EditEvent = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [longDescriptionCharCount, setLongDescriptionCharCount] = useState(0)
    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const events = useEvents().events
    const url = window.location.pathname
    const [event, setEvent] = useState()
    const eventId = url.split('/').pop()
    const formRef = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (events?.length > 0) {
            const activity = events.find(event => event.id === eventId)
            if (activity) {
                setEvent(activity)
            } else {
                handleGetEvent(eventId)
            }
        } else {
            handleGetEvent(eventId)
        }

        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }
    }, [])

    useEffect(() => {
        if (event) {
            formRef.current.setFieldsValue({
                eventName: event.eventName,
                destinationId: event.destinationId,
                tags: event.tags.split(','),
                eventDescriptionShort: event.eventDescriptionShort,
                eventDescription: event.eventDescription,
                rules: event.rules,
                eventType: event.eventType,
                eventStartDate: event.eventStartDate,
                eventEndDate: event.eventEndDate,
                eventCity: event.eventCity,
                eventCoordinates: event.eventCoordinates,
                mapLocation: event.mapLocation,
                weather: event.weather,
                ticketPrice: event.ticketPrice,
                activeStatus: event.activeStatus,
            })

            if (event.eventImages) {
                const images = event.eventImages.split(',')
                const fileList = images.map((url, index) => {
                    return {
                        uid: index,
                        name: `image_${index}.png`,
                        status: 'done',
                        url: url
                    }
                })
                setFileList(fileList)
            }

            if (event.eventVideos) {
                const videos = event.eventVideos.split(',')
                const fileList = videos.map((url, index) => {
                    return {
                        uid: index,
                        name: `video_${index}.mp4`,
                        status: 'done',
                        url: url
                    }
                })
                setVideoList(fileList)
            }

            if (event.thumbnailUrl) {
                const fileList = [{
                    uid: 0,
                    name: 'thumbnail.png',
                    status: 'done',
                    url: event.thumbnailUrl
                }]
                setThumbnailFile(fileList)
            }
        }
    }, [event])

    const handleGetEvent = async (id) => {
        setLoading(true)
        try {
            const event = await getEventById(id)
            setEvent(event.data[0])
        } catch (error) {
            console.error('Error fetching event', error)
        }
        setLoading(false)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    const onThumbnailChange = ({ fileList: newFileList }) => setThumbnailFile(newFileList);


    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];
            let videoUrls = [];
            let thumbnailUrl = '';

            const name = values.eventName.replace(/\s/g, '_').toLowerCase();

            // find new images and list images need to be deleted
            const newImages = fileList?.filter(file => !file.url);
            const deletedImages = event.eventImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            // find new videos and list videos need to be deleted
            const newVideos = videoList.filter(file => !file.url);
            const deletedVideos = event.eventVideos?.split(',').filter(video => !videoList.find(file => file.url === video));

            // find new thumbnail and list thumbnail need to be deleted
            const newThumbnail = thumbnailFile.filter(file => !file.url);
            const deletedThumbnail = event.thumbnailUrl?.split(',').filter(thumbnail => !thumbnailFile.find(file => file.url === thumbnail));

            if (newImages?.length > 0) {
                setLoadingText('Uploading Event Images');
                const imageUploadPromises = newImages.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        EVENTS_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        imageUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            if (newVideos?.length > 0) {
                setLoadingText('Uploading Event Videos');
                const videoUploadPromises = newVideos.map(async (file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);
                    return handleImageUpload(
                        EVENTS_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        videoUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            if (newThumbnail?.length > 0) {
                setLoadingText('Uploading Event Thumbnail');
                const thumbnailUploadPromises = newThumbnail.map(async (file, index) => {
                    setLoadingText(`Uploading thumbnail ${index + 1}`);
                    return handleImageUpload(
                        EVENTS_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        thumbnailUrl = response.blockBlobClient.url;
                    });
                });
                await Promise.all(thumbnailUploadPromises);
            }

            // delete blobs
            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Event Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(EVENTS_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (deletedVideos?.length > 0) {
                setLoadingText('Deleting Event Videos');
                const videoDeletePromises = deletedVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(EVENTS_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (deletedThumbnail?.length > 0) {
                setLoadingText('Deleting Event Thumbnail');
                const thumbnailDeletePromises = deletedThumbnail.map(async (thumbnail, index) => {
                    const blobName = getBlobName(thumbnail);
                    setLoadingText(`Deleting thumbnail ${index + 1}`);
                    return handleDeleteBlob(EVENTS_CONTAINER, blobName);
                });
                await Promise.all(thumbnailDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            let updatedVideos = [
                ...videoList.filter((file) => file.url).map((file) => file.url),
                ...videoUrls
            ];

            // Ensure thumbnailUrl is a single string
            let updatedThumbnail = thumbnailUrl || (thumbnailFile[0]?.url || '');

            // remove unwanted parts from urls
            updatedImages.forEach((url, index) => {
                updatedImages[index] = sanitizeBlobUrls(url);
            });

            updatedVideos.forEach((url, index) => {
                updatedVideos[index] = sanitizeBlobUrls(url);
            });

            updatedThumbnail = sanitizeBlobUrls(updatedThumbnail);

            const data = {
                id: event.id,
                destinationId: values.destinationId,
                eventName: values.eventName,
                tags: values.tags.join(','),
                eventDescriptionShort: values.eventDescriptionShort,
                eventDescription: values.eventDescription,
                eventType: values.eventType,
                eventStartDate: values.eventStartDate,
                eventEndDate: values.eventEndDate,
                eventCity: values.eventCity,
                eventCoordinates: values.eventCoordinates,
                mapLocation: values.mapLocation,
                weather: values.weather,
                rules: values.rules ? values.rules : null,
                ticketPrice: values.ticketPrice.toString(),
                activeStatus: values.activeStatus,
                eventImages: updatedImages?.length > 0 ? updatedImages.join(',') : null,
                eventVideos: updatedVideos?.length > 0 ? updatedVideos.join(',') : null,
                thumbnailUrl: updatedThumbnail === '' ? null : updatedThumbnail
            }

            const response = await editEvent(data);
            if (response.status === 200) {
                api.success({
                    message: 'Event Updated Successfully',
                    description: 'You have successfully updated the event',
                });

                setTimeout(() => {
                    navigate(`/dashboard/tours/events/${event.id}`);
                }, 2000);
            } else {
                console.log('Error', response);
                api.error({
                    message: 'Error Updating Event',
                    description: 'There was an error updating the event. Please try again'
                });
            }
        } catch (error) {
            console.log('Error', error);
            api.error({
                message: 'Error!',
                description: 'Failed to update the event. Please try again.',
            });
        }
        setLoading(false);
        setLoadingText('');
    };

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>Edit Event</h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Event Name'
                                name='eventName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter event name'
                                    }
                                ]}
                            >
                                <Input placeholder='Event Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Destination'
                                name='destinationId'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select destination'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Destination"
                                    options={destinationOptions}
                                    maxCount={1}
                                    filterOption={(input, option) => { return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Event Short Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='eventDescriptionShort'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter event short description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Event Description ( ${longDescriptionCharCount}/10000 characters)`}
                                name='eventDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter event description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Rules ( ${longDescriptionCharCount}/500 characters)`}
                                name='rules'
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Event Type'
                                name='eventType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select event type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Event Type"
                                    options={eventOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Event City'
                                name='eventCity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add event city'
                                    }
                                ]}
                            >
                                <Input placeholder='Event City' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Event Coordinates'
                                name='eventCoordinates'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input event coordinates'
                                    }
                                ]}
                            >
                                <Input placeholder='Event Coordinates' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Event Map Location'
                                name='mapLocation'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add event map location'
                                    }
                                ]}
                            >
                                <Input placeholder='Event Map Location' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Event Weather'
                                name='weather'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add weather"
                                    options={weatherOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Ticket Price (in USD). Add 0 if free'
                                name='ticketPrice'
                            >
                                <Input type='number' placeholder='Ticket Price' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Event Tags'
                                name='tags'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add tags'
                                    }
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add activity tags"
                                    options={[]}
                                    maxCount={5}
                                />
                            </Form.Item>
                        </Col>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '50%'
                            }}
                        >
                            <Col span={12}>
                                <Form.Item
                                    label='Event Start Date'
                                    name='eventStartDate'
                                >
                                    <Input type='date' placeholder='Event Start Date' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Event End Date'
                                    name='eventEndDate'
                                >
                                    <Input type='date' placeholder='Event End Date' />
                                </Form.Item>
                            </Col>
                        </div>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Event Images (Max 10)'
                                name='eventImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Event Videos (Max 3)'
                                name='eventVideos'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={videoList}
                                    onChange={onVideoChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 3 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Event Thumbnail Image'
                                name='thumbnailUrl'
                            >
                                <ImgCrop
                                    rotationSlider
                                    modalTitle='Crop Image'
                                    modalOk='Crop'
                                    modalCancel='Cancel'
                                    showReset={true}
                                    aspect={16 / 9}
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={thumbnailFile}
                                        onChange={onThumbnailChange}
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {thumbnailFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={12}>
                        <Form.Item
                            label='Active Status'
                            name='activeStatus'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select active status'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Active Status"
                                options={[
                                    {
                                        label: 'Active',
                                        value: ACTIVE
                                    },
                                    {
                                        label: 'Inactive',
                                        value: INACTIVE
                                    },
                                    {
                                        label: 'Pending',
                                        value: PENDING
                                    }
                                ]}
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Update Event
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditEvent