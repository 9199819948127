import React, { useEffect, useState } from 'react'
import useDestinations from '../../../../../hooks/useDestinations'
import { useNavigate } from 'react-router-dom'

import { ACTIVITIES_CONTAINER, handleDeleteBlob } from '../../../../../azure/blob'
import useActivities from '../../../../../hooks/useActivities';
import { deleteActivity, getActivityById } from '../../../../../../redux/api/activities.api';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Image, Popconfirm, Spin, Tag, notification } from 'antd'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';


import './styles.scss'
import useMyProfile from '../../../../../hooks/useMyProfile';
import { ADMIN, SUPER_ADMIN } from '../../../../../Constants/roles';

const Activity = () => {
    const destinations = useDestinations()
    const activities = useActivities().activities
    const destinationNames = destinations.destinationNames
    const url = window.location.pathname
    const activityId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [activity, setActivity] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loadingText, setLoadingText] = useState('Please Wait...')
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        if (activities?.length > 0) {
            const activity = activities.find(activity => activity.id === activityId)
            if (activity) {
                setActivity(activity)
            } else {
                handleGetActivity(activityId)
            }
        } else {
            handleGetActivity(activityId)
        }
    }, [])

    const handleGetActivity = async (id) => {
        setLoading(true)
        try {
            const activity = await getActivityById(id)
            setActivity(activity.data[0])
        } catch (error) {
            console.error('Error fetching activity', error)
        }
        setLoading(false)
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const handleDeleteActivity = async () => {
        setLoading(true)
        try {
            // get all blobs
            let activityImages = activity.activityImages ? activity.activityImages.split(',') : []
            let activityVideos = activity.activityVideos ? activity.activityVideos.split(',') : []
            let thumbnail = activity.thumbnailUrl

            if (activityImages.length > 0) {
                setLoadingText('Deleting Activity Images');
                const imageDeletePromises = activityImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACTIVITIES_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (activityVideos.length > 0) {
                setLoadingText('Deleting Activity Videos');
                const videoDeletePromises = activityVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(ACTIVITIES_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (thumbnail) {
                setLoadingText('Deleting Activity Thumbnail');
                const blobName = getBlobName(thumbnail);
                setLoadingText(`Deleting thumbnail`);
                await handleDeleteBlob(ACTIVITIES_CONTAINER, blobName);
            }

            const res = await deleteActivity(activityId)

            if (res.status === 200) {
                api.success({
                    message: 'Activity deleted successfully',
                    description: 'The activity has been successfully deleted'
                })

                setTimeout(() => {
                    navigate('/dashboard/tours/activities')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting activity',
                    description: 'An error occurred while deleting the activity'
                })
            }

        } catch (error) {
            console.error('Error deleting activity', error)
            api.error({
                message: 'Error deleting activity',
                description: error.message
            })
        }
        setLoading(false)
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {activity?.activityName}
                        </h1>
                        {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                            <div className='destination__header__actions'>
                                <Button type='primary' onClick={() => navigate(`/dashboard/tours/activities/edit/${activityId}`)} icon={<EditOutlined />}>
                                    Edit
                                </Button>
                                <Popconfirm
                                    title='Are you sure you want to delete this activity?'
                                    description={<p
                                        style={{
                                            width: '300px',
                                            color: 'red',
                                        }}
                                    >This action cannot be undone! This will permanently delete the activity, its images, videos, attractions and other related items.</p>}
                                    onConfirm={handleDeleteActivity}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button danger type='primary' icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>}
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__image'>
                            <img src={activity?.thumbnailUrl} alt='activity' />
                        </div>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup>
                                {activity?.activityImages?.split(',').map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <Divider />
                    <div className='destination__content_videos'>
                        <h2>Videos</h2>
                        <div className='destination__content__videos'>
                            {activity?.activityVideos ? <>
                                {activity?.activityVideos?.split(',').map((video, index) => (
                                    <video key={index} controls>
                                        <source src={video} type='video/mp4' />
                                    </video>
                                ))}
                            </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='destination__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='left'>
                                <p><strong>City: </strong>{activity?.activityCity || 'N/A'}</p>
                                <p><strong>Coordinates: </strong>{activity?.activityCoordinates || 'N/A'}</p>
                                <p><strong>Weather: </strong>{activity?.weather || 'N/A'}</p>
                                <p><strong>Type: </strong>{activity?.activityType || 'N/A'}</p>
                            </div>
                            <div className='right'>
                                <p><strong>Destination: </strong>
                                    {destinationNames?.find(destination => destination.id === activity?.destinationId)?.destinationName || 'N/A'}
                                </p>
                                <p><strong>Tags: </strong>
                                    {activity?.tags?.split(',').map((tag, index) => (
                                        <Tag key={index}>{tag} </Tag>
                                    ))}
                                </p>
                                <p><strong>Ticket: </strong>{activity?.ticketPrice || 'N/A'}</p>
                                <p><strong>Min Age: </strong>{activity?.minAge || 'N/A'}
                                    <strong
                                        style={{
                                            marginLeft: '10px'
                                        }}
                                    >Max Age: </strong>{activity?.maxAge || 'N/A'}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='destination__content_description'>
                        <h2>
                            Descriptions
                        </h2>
                        <h3>
                            Short Description
                        </h3>
                        <ReactQuill
                            value={activity?.activityDescriptionShort}
                            readOnly={true}
                            theme='bubble'
                        />

                        <h3>
                            Full Description
                        </h3>
                        <ReactQuill
                            value={activity?.activityDescription}
                            readOnly={true}
                            theme='bubble'
                        />


                        <h3>
                            Rules
                        </h3>
                        <ReactQuill
                            value={activity?.rules}
                            readOnly={true}
                            theme='bubble'
                        />
                    </div>

                    <Divider />
                    <div className='destination__content_reviews'>
                        <h2>
                            Reviews
                        </h2>
                    </div>

                    <Divider />

                    <div className='destination__content_emergency'>
                        <h2>
                            Emergency Contacts
                        </h2>
                    </div>
                </>
            </Spin>
        </div>
    )
}

export default Activity