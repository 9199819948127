import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { CANCELLED, CONFIRMED, INSTALMENT_PAID, INSTALMENT_PENDING, PAID, PENDING, PENDING_PAYMENT, PENDING_VERIFICATION, REJECTED, REQESTED } from '../../../../../Constants/finance';
import { capitalizeFisrtLetterEachWord } from '../../../../../helpers/nameRender';

const AccommodationBookingTable = ({
    bookings,
    handleFetchBookings,
    page,
    setPage,
    loading
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        setTableData(bookings)
    }, [bookings])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Link to={`/dashboard/bookings/hotels/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px'
                        }}
                    >
                        <EyeOutlined />
                        View
                    </p>
                </Link>
            ),
        },
        {
            title: 'Package',
            dataIndex: 'packageName',
            key: 'packageName',
            width: '20%',
        },
        {
            title: 'Accommodation',
            dataIndex: 'accommodationName',
            key: 'accommodationName',
            width: '20%',
            render: (text, record) => (
                <Link to={`/dashboard/partners/hotels/${record.hotelId}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
        },
        {
            title: 'Booking ID',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Check-In',
            dataIndex: 'checkIn',
            key: 'checkIn',
            width: '20%',
            sorter: (a, b) => new Date(a.checkIn) - new Date(b.checkIn),
            sortDirections: ['descend'],
            showSorterTooltip: { target: 'full-header' },
        },
        {
            title: 'Check-Out',
            dataIndex: 'checkOut',
            key: 'checkOut',
            width: '20%',
            sorter: (a, b) => new Date(a.checkOut) - new Date(b.checkOut),
            sortDirections: ['descend'],
            showSorterTooltip: { target: 'full-header' },
        },
        {
            title: 'Booking Status',
            dataIndex: 'bookingStatus',
            key: 'bookingStatus',
            width: '10%',
            // filters: [
            //     {
            //         text: 'Confirmed',
            //         value: CONFIRMED,
            //     },
            //     {
            //         text: 'Pending',
            //         value: PENDING,
            //     },
            //     {
            //         text: 'Rejected',
            //         value: REJECTED
            //     }
            // ],
            // onFilter: (value, record) => record.bookingStatus.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === CONFIRMED) {
                    color = 'green';
                } else if (text === PENDING) {
                    color = 'volcano';
                } else if (text === REJECTED) {
                    color = 'red';
                } else if (text === "REQUESTED") {
                    color = 'magenta';
                }
                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : ''}
                    </Tag>
                );
            }
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            width: '10%',
            // filters: [
            //     {
            //         text: 'Pending Payment',
            //         value: PENDING_PAYMENT,
            //     },
            //     {
            //         text: 'Pending Verification',
            //         value: PENDING_VERIFICATION,
            //     },
            //     {
            //         text: 'Paid',
            //         value: PAID
            //     },
            //     {
            //         text: 'Instalment Pending',
            //         value: INSTALMENT_PENDING
            //     },
            //     {
            //         text: 'Instalment Paid',
            //         value: INSTALMENT_PAID
            //     },
            //     {
            //         text: 'Cancelled',
            //         value: CANCELLED
            //     }
            // ],
            render: (text) => {
                let color = 'geekblue';
                if (text === PENDING_PAYMENT) {
                    color = 'volcano';
                } else if (text === PENDING_VERIFICATION) {
                    color = 'green';
                } else if (text === PAID) {
                    color = 'green';
                } else if (text === INSTALMENT_PENDING) {
                    color = 'red';
                } else if (text === INSTALMENT_PAID) {
                    color = 'red';
                } else if (text === CANCELLED) {
                    color = 'red';
                } else if (text === REQESTED) {
                    color = 'red';
                }

                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : ''}
                    </Tag>
                );
            }
        },
        {
            title: 'Final Amount',
            dataIndex: 'total',
            key: 'total',
            width: '22%',
            render: (text) => {
                return text ?
                    `${text} AUD`
                    : 'N/A';
            }
        },
    ];

    return (
        <Table
            className='table'
            loading={loading}
            columns={columns}
            dataSource={tableData}
            pagination={{
                pageSize: 10,
                position: ["bottomRight"],
                current: page,
                total: bookings[0]?.count,
                onChange: (page) => {
                    setPage(page)
                    handleFetchBookings(page)
                },
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
        />
    )
}

export default AccommodationBookingTable